<template>
  <v-container fluid full-width>
    <v-row justify="center">
      <v-col xs="12" sm="12" md="4" lg="3" xl="2">
        <v-dialog v-model="modal" persistent width="290px">
          <template v-slot:activator="{ on, attrs }">
            <v-select
              class="mt-4"
              :items="['ANY', 'LAL', 'GAYAN']"
              v-model="staff"
              label="Select Technician"
              outlined
              dark
            >
              <template v-slot:prepend>
                <div class="text-h6 mr-6 pb-6">Technician</div>
              </template></v-select
            >
            <v-text-field
              class="dateText"
              dark
              v-model="date"
              label="Pick a date"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="date"
            scrollable
            :allowed-dates="allowedDates"
            first-day-of-week="1"
          >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="modal = false"> Cancel </v-btn>
            <v-btn text color="primary" @click="getDateStatus(date)"> OK </v-btn>
          </v-date-picker>
        </v-dialog>
      </v-col>
    </v-row>
    <v-row v-if="loading" justify="center" align="center">
      <v-col cols="12">
        <div class="text-center mt-15">
          <v-progress-circular
            :size="200"
            :width="16"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </div>
      </v-col>
    </v-row>
    <v-row v-if="disabled && !loading" justify="center" align="center">
      <v-col xs="12" sm="12" md="4" lg="6" xl="4">
        <v-img src="@/assets/closed.png" contain height="200"> </v-img>
      </v-col>
    </v-row>
    <v-row v-if="!disabled && !loading" justify="space-around" no-gutters>
      <v-col cols="6" xs="6" sm="6" md="4" lg="3" xl="2">
        <template v-for="slot in slots1">
          <v-list-item :key="slot.slot">
            <template>
              <v-btn v-if="slot.disabled" disabled block>{{ slot.slot }}</v-btn>
              <v-btn
                v-if="slot.booked && !slot.disabled"
                :class="bookedCheck(slot.userID)"
                dark
                block
                @click="snackbar = true"
                elevation="0"
                >{{ slot.slot }}</v-btn
              >
              <v-btn
                v-if="!slot.booked && !slot.disabled"
                color="white"
                outlined
                block
                @click="confirmation(slot.slot)"
                >{{ slot.slot }}</v-btn
              >
            </template></v-list-item
          ></template
        >
      </v-col>
      <v-col cols="6" xs="6" sm="6" md="4" lg="3" xl="2">
        <template v-for="slot in slots2">
          <v-list-item :key="slot.slot">
            <template>
              <v-btn v-if="slot.disabled" disabled block>{{ slot.slot }}</v-btn>
              <v-btn
                v-if="slot.booked && !slot.disabled"
                :class="bookedCheck(slot.userID)"
                dark
                block
                @click="snackbar = true"
                elevation="0"
                >{{ slot.slot }}</v-btn
              >
              <v-btn
                v-if="!slot.booked && !slot.disabled"
                color="white"
                outlined
                block
                @click="confirmation(slot.slot)"
                >{{ slot.slot }}</v-btn
              >
            </template></v-list-item
          ></template
        >
      </v-col>
    </v-row>
    <v-divider class="my-5"></v-divider>
    <v-row
      v-if="!loading && !disabled"
      justify="space-around"
      align="center"
      class="mx-2 mb-2"
    >
      <v-col cols="2">
        <v-row justify="center">
          <v-btn color="white" outlined max-height="10" block></v-btn>
          <div class="white--text ledgendText">VACANT</div>
        </v-row> </v-col
      ><v-col cols="2">
        <v-row justify="center">
          <v-btn color="red darken-4" rounded max-height="10" block></v-btn>
          <div class="white--text ledgendText">RESERVED</div>
        </v-row>
      </v-col>
      <v-col cols="2">
        <v-row justify="center">
          <v-btn color="green darken-4" max-height="10" block></v-btn>
          <div class="white--text ledgendText">YOUR SLOT</div>
        </v-row>
      </v-col>
      <v-col cols="2">
        <v-row justify="center">
          <v-btn max-height="10" block disabled></v-btn>
          <div class="white--text ledgendText">UNAVAILABLE</div>
        </v-row>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" :timeout="timeout">
      Already reserved!
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
      </template>
    </v-snackbar>
    <v-dialog v-model="confirmDialog" max-width="290" persistent>
      <v-card>
        <v-card-title class="text-h6">Reserve this time slot?</v-card-title>

        <v-card-text class="text-body-1">
          Do you want to check and reserve {{ selectedSlot }} time slot on
          {{ selectedDate }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green darken-1"
            text
            @click="confirmDialog = false"
            :disabled="working"
          >
            Cancel
          </v-btn>

          <v-btn color="green darken-1" text @click="bookSlot()" :loading="working">
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="successDialog" max-width="290" persistent>
      <v-card>
        <v-card-title class="text-h6 green--text">Success</v-card-title>

        <v-card-text class="text-body-1">
          Your appointment is set!
          <div>Please arrive on time for your appointment.</div>
          <p></p>
          <div>
            <strong>Date - {{ selectedDate }}</strong>
          </div>
          <div>
            <strong>Time - {{ selectedSlot }}</strong>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="reload()"> Okay </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="errorDialog" max-width="290" persistent>
      <v-card>
        <v-card-title class="text-h6 red--text">{{ errorTitle }}</v-card-title>

        <v-card-text class="text-body-1">
          {{ errorText }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="reload()"> {{ errorAction }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { firebaseAuth, firebaseFunctions, firebaseFirestore, serverTime } from "@/main";
import { EventBus } from "@/main";
import moment from "moment";

export default {
  data: () => ({
    loading: true,
    working: false,
    today: new Date(),
    date: "",
    modal: false,
    dates: [],
    selectedDate: "",
    slots1: [],
    slots2: [],
    disabled: false,
    confirmDialog: false,
    successDialog: false,
    errorDialog: false,
    errorTitle: "",
    errorText: "",
    errorAction: "",
    selectedSlot: "",
    snackbar: false,
    timeout: 2000,
    staff: "ANY",
  }),
  components: {},
  beforeMount() {
    this.loading = true;
    this.emitLoadingState();
    this.selectedDate = this.$route.params.dateParam;
    this.date = this.$route.params.dateParam;
    this.getdates();
  },
  methods: {
    emitLoadingState() {
      EventBus.$emit("loadingState", this.loading);
    },
    getdates() {
      firebaseFirestore
        .collection("appointments")
        .where("timestamp", ">", this.today)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.dates.push(this.formatDate(doc.data().timestamp));
          });
        })
        .then(() => {
          this.selectedDate = this.formatToPath(this.date).toString();

          firebaseFirestore
            .collection("appointments")
            .doc(this.selectedDate)
            .get()
            .then((docSnap) => {
              if (docSnap.data().disabled == false) {
                this.disabled = false;
                this.getSlots();
              } else {
                this.slots1 = [];
                this.slots2 = [];
                this.disabled = true;
                this.loading = false;
                this.emitLoadingState();
              }
            });
        });
    },
    getDateStatus(date) {
      this.date = date;
      this.modal = false;
      this.selectedDate = this.formatToPath(this.date).toString();

      this.loading = true;
      this.emitLoadingState();

      firebaseFirestore
        .collection("appointments")
        .doc(this.selectedDate)
        .get()
        .then((docSnap) => {
          if (docSnap.data().disabled == false) {
            this.disabled = false;
            this.getSlots();
          } else {
            this.slots1 = [];
            this.slots2 = [];
            this.disabled = true;
            this.loading = false;
            this.emitLoadingState();
          }
        });
    },
    getSlots() {
      this.slots1 = [];
      this.slots2 = [];

      firebaseFirestore
        .collection("appointments")
        .doc(this.selectedDate)
        .collection("slots")
        .orderBy("slot")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const data = {
              booked: doc.data().booked,
              disabled: this.disabledCheck(
                doc.data().disabled,
                doc.data().start.toDate()
              ),
              slot: doc.data().slot,
              userID: doc.data().userID,
            };
            this.slots1.push(data);
            this.slots2.push(data);
          });
        })
        .then(() => {
          this.slots1 = this.slots1.slice(0, 8);
          this.slots2 = this.slots2.slice(8);
          this.loading = false;
          this.emitLoadingState();
        });
    },
    allowedDates(val) {
      if (this.dates.indexOf(val) !== -1) {
        return true;
      } else {
        return false;
      }
    },
    formatDate(timeStamp) {
      if (timeStamp == "") {
        return timeStamp;
      } else {
        var m = moment(timeStamp.toDate());
        var mFormatted = m.format("YYYY-MM-DD");
        return mFormatted;
      }
    },
    formatToPath(dateString) {
      var momentObj = moment(dateString, "YYYY-MM-DD");
      var momentString = momentObj.format("DD-MMM-YYYY");
      console.log(momentString);
      return momentString;
    },
    bookedCheck(userID) {
      if (firebaseAuth.currentUser.uid == userID) {
        return "green darken-4";
      } else {
        return "red darken-4";
      }
    },
    disabledCheck(disabled, start) {
      var nowTime = new Date();
      if (disabled == true) {
        return true;
      } else {
        if (start < nowTime) {
          return true;
        } else {
          return false;
        }
      }
    },
    confirmation(slot) {
      this.selectedSlot = slot;
      this.confirmDialog = true;
    },
    bookSlot() {
      this.working = true;

      var createAppointment = firebaseFunctions.httpsCallable("createAppointment");
      createAppointment({
        dateString: this.selectedDate,
        slotString: this.selectedSlot,
        staff: this.staff,
      }).then((result) => {
        if (result.data.response == "Success") {
          this.confirmDialog = false;
          this.working = false;
          this.successDialog = true;
        } else if (result.data.response == "Already") {
          this.errorTitle = "One already set!";
          this.errorText = result.data.error;
          this.errorAction = "Okay";
          this.confirmDialog = false;
          this.working = false;
          this.errorDialog = true;
        } else if (result.data.response == "Error") {
          this.errorTitle = "Error";
          this.errorText = result.data.error;
          this.errorAction = "Try Again";
          this.confirmDialog = false;
          this.working = false;
          this.errorDialog = true;
        }
      });
    },
    reload() {
      window.location.href = "/appointment/" + this.date;
    },
  },
};
</script>

<style scoped>
.dateText {
  font-size: 1.2em;
  font-weight: bolder;
}

.ledgendText {
  font-size: 0.6em;
  text-align: center;
}
</style>
