<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col cols="12" xs="12" sm="12" md="6" lg="4" xl="3">
      <v-card class="mx-2 mt-2 mb-6 pa-4" dark> Fill your name and upload profile photo to get verified
      </v-card>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-text-field
            v-model="this.mobile"
            prepend-icon="mdi-cellphone"
            hint="Mobile - Cannot be changed"
            persistent-hint
            disabled
            rounded
            dark
            filled
          ></v-text-field>
          <v-text-field
            v-model="name"
            rounded
            dark
            filled
            prepend-icon="mdi-account"
            counter="35"
            :disabled="nameUpdating"
            hint="Name"
            persistent-hint
          >
          </v-text-field>
          <v-divider dark></v-divider>
          <v-file-input
            v-model="profileFile"
            class="mt-4"
            dark
            rounded
            label="Choose Photo"
            filled
            prepend-icon="mdi-image"
            type="file"
            accept="image/*"
            @change="setImage"
            :clearable="clearable"
            :rules="[rules.fileRequired, rules.profileSize]"
          ></v-file-input>
        </v-form>
        <div v-if="profileFile != null && !valid" class="fileLimit red--text">
          Image should be below 2MB
        </div>
        <vue-cropper
          v-if="profileFile != null && valid"
          ref="cropper"
          :guides="true"
          :view-mode="2"
          drag-mode="crop"
          :auto-crop-area="0.5"
          :min-container-width="250"
          :max-container-height="200"
          :minCropBoxHeight="100"
          :background="true"
          :rotatable="false"
          :src="imgSrc"
          :img-style="{ width: '400px', height: '400px' }"
          :aspect-ratio="1"
          outputType="jpeg"
          img="@/assets/profile.jpg"
        ></vue-cropper>
        <v-row justify="center" align="center">
          <v-col cols="12">
            <v-img
              contain
              max-height="200"
              v-if="profileFile == null && valid"
              :src="this.profile"
              lazy-src="https://firebasestorage.googleapis.com/v0/b/royal-hair-care.appspot.com/o/profile.jpg?alt=media&token=c4156c55-7064-421d-bf1d-910d94870210"
            >
              <template v-slot:placeholder>
                <v-row align="center" justify="center" class="mt-10">
                  <v-progress-circular indeterminate color="black"></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-col>
        </v-row>
        <v-btn
          block
          x-large
          class="primary my-3"
          @click="updateName"
          v-if="(profileFile != null && valid) || saveButton"
          :loading="working"
        >
          Update profile
        </v-btn>
        <v-progress-linear
          v-if="working"
          v-model="progress"
          :indeterminate="progressIntermidiate"
          height="25"
          stream
          rounded
          striped
          color="grey"
          ><strong>{{ Math.ceil(progress) }}% uploded</strong></v-progress-linear
        >
      </v-col>
    </v-row>
    <v-dialog v-model="dialog" max-width="290" persistent>
      <v-card dark>
        <v-card-title :class="this.dialogTitleClass">{{ dialogTitle }}</v-card-title>

        <v-card-text class="text-body-1">
          {{ dialogBody }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text href="/">Okay</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { firebaseAuth, firebaseFirestore, firebaseStorage, firebaseFunctions } from "@/main";
import { EventBus } from "@/main";
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";

export default {
  data: () => ({
    valid: false,
    loading: true,
    working: false,
    mobile: "",
    name: "",
    oldName: "",
    profile: localStorage.profile,
    nameUpdating: false,
    disabled: true,
    clearable: false,
    profileFile: null,
    imgSrc: "",
    cropImg: "",
    rules: {
      fileRequired: (v) => !!v || "File is required",
      profileSize: (value) =>
        !value || value.size < 40000000 || "File Size should be less than 40 MB!",
    },
    progress: 0,
    progressIntermidiate: true,
    progressText: "",
    dialog: false,
    dialogTitle: "",
    dialogBody: "",
    dialogTitleClass: "",
  }),
  components: {
    VueCropper,
  },
  created() {
    this.loading = true;
    this.emitLoadingState();
    this.getUserDetails(firebaseAuth.currentUser.uid);
  },
  computed: {
    saveButton() {
      if (this.oldName == this.name || this.name == "") {
        return false;
      } else {
        return true;
      }
    },
    croppedFile() {
      return new File([this.cropImg], this.profileFile.name, {
        type: this.profileFile.type,
      });
    },
    cropSrc() {
      return this.cropImg || this.value;
    },
  },
  methods: {
    emitLoadingState() {
      EventBus.$emit("loadingState", this.loading);
    },
    getUserDetails(userID) {
      firebaseFirestore
        .collection("users")
        .doc(userID)
        .get()
        .then((docSnap) => {
          this.mobile = docSnap.data().mobile;
          this.name = docSnap.data().name;
          this.oldName = docSnap.data().name;
        })
        .then(() => {
          this.loading = false;
          this.emitLoadingState();
        });
    },
    updateName() {
      this.working = true;
      this.$refs["cropper"].disable();
      if (this.saveButton == true) {
        this.working = true;

        var setName = firebaseFunctions.httpsCallable("setName");
        setName({
          name: this.name,
        }).then((result) => {
          if (result.data.response == "Success") {
            localStorage.name = this.name;
            this.cropImage();
          } else if (result.data.response == "Error") {
            this.working = false;
            this.$refs["cropper"].enable();
            this.dialogTitle = "Error!";
            this.dialogTitleClass = "text-h6 red--text";
            this.dialogBody = "Error setting name" + result.data.message;
            this.dialog = true;
          }
        });
      } else {
        this.cropImage();
      }
    },
    setImage() {
      if (this.profileFile != null) {
        this.valid = this.$refs.form.validate();

        let reader = new FileReader();
        reader.readAsDataURL(this.profileFile);
        reader.onload = (event) => {
          const src = event.target.result;
          const img = new Image();

          this.$refs.cropper.replace(src);
          this.imgSrc = src;

          img.onload = () => {
            this.imgSrc = event.target.result;
          };

          img.onerror = () => {
            console.log("Error during image select");
          };
        };
      }
    },
    cropImage() {
      if (this.profileFile != null) {
        // get image data for post processing, e.g. upload or setting image src
        this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL();
        this.uploadProfile();
      } else {
        this.working = false;
        this.dialogTitle = "Success!";
        this.dialogTitleClass = "text-h6 green--text";
        this.dialogBody = "Your profile has been updated";
        this.dialog = true;
      }
    },
    uploadProfile() {
      this.working = true;
      let uploadFile;
      let fileURL = this.$refs.cropper.getCroppedCanvas().toDataURL();

      uploadFile = this.dataURLtoFile(fileURL, "thumb");

      var selfThis = this;
      selfThis.progressIntermidiate = false;
      const imageID = parseInt(Math.random() * 9999999999).toString();

      const storageRef = firebaseStorage.ref();
      const thumbPath = storageRef.child(
        "users/" + firebaseAuth.currentUser.uid + "/profile/" + imageID + ".jpg"
      );
      const metadata = {
        cacheControl: "public,max-age=3600",
        contentType: "image/jpeg",
      };

      var uploadTask = thumbPath.put(uploadFile, metadata);

      return new Promise(function (resolve, reject) {
        uploadTask.on(
          "state_changed",
          function (snapshot) {
            selfThis.progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          },
          function (error) {
            console.log(error);
            selfThis.working = false;
            selfThis.dialogTitle = "Error!";
            selfThis.dialogTitleClass = "text-h6 red--text";
            selfThis.dialogBody = "Error while updating profile picture. " + error;
            selfThis.dialog = true;
          },
          function () {
            selfThis.working = false;
            selfThis.dialogTitle = "Success!";
            selfThis.dialogTitleClass = "text-h6 green--text";
            selfThis.dialogBody = "Your profile has been updated";
            selfThis.dialog = true;
          }
        );
      });
    },
    dataURLtoFile(dataUrl, fileName) {
      var arr = dataUrl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], fileName, { type: mime });
    },
  },
};
</script>

<style scoped>
.fileLimit {
  text-align: center;
  font-size: 1.3em;
}
</style>
