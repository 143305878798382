var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-app-bar',{attrs:{"app":"","dark":"","color":"primary","shrink-on-scroll":"","prominent":"","src":require("@/assets/rhcBackground.jpg"),"fade-img-on-scroll":""},scopedSlots:_vm._u([{key:"img",fn:function(ref){
var props = ref.props;
return [_c('v-img',_vm._b({attrs:{"gradient":"to top right, rgba(0,0,0,.7), rgba(66,66,66,.7)"}},'v-img',props,false))]}}])},[_c('v-app-bar-title',[_vm._v(_vm._s(_vm.title))]),_c('v-spacer'),_c('v-btn',{staticClass:"mr-3",attrs:{"fab":"","href":"/","color":"black"}},[_c('v-icon',[_vm._v("mdi-home")])],1),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-width":200,"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","color":"black"},on:{"click":_vm.setProfile}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" mdi-menu ")])],1)]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-card',[_c('v-list',[_c('v-list-item',[_c('v-list-item-content',{style:({
                'align-item': 'center',
              })},[_c('v-avatar',{attrs:{"size":"120"}},[_c('v-img',{attrs:{"max-width":"120","src":this.profile,"lazy-src":"https://firebasestorage.googleapis.com/v0/b/royal-hair-care.appspot.com/o/profile.jpg?alt=media&token=c4156c55-7064-421d-bf1d-910d94870210"},on:{"error":_vm.imageLoadError},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"mt-10",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"black"}})],1)]},proxy:true}])})],1)],1)],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-h6"},[_vm._v(_vm._s(this.mobile))]),_c('v-list-item-subtitle',{staticClass:"text-button"},[_vm._v(_vm._s(this.name))])],1),(_vm.state==2 || _vm.state==3)?_c('v-chip',{attrs:{"color":"green","outlined":"","pill":"","small":""}},[_vm._v(" Verified "),_c('v-icon',{attrs:{"right":"","small":""}},[_vm._v(" mdi-account-outline ")])],1):_vm._e()],1)],1),_c('v-divider'),_c('v-list',[_c('v-list-item',[_c('v-btn',{attrs:{"text":"","block":"","large":"","href":"/profile"}},[_vm._v(" Edit profile ")])],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.logout}},[_vm._v(" Logout ")])],1)],1)],1),_c('v-progress-linear',{attrs:{"active":_vm.loading,"indeterminate":"","absolute":"","bottom":"","height":"3","color":"white"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }