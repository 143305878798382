<template>
  <v-container class="loginBack" fill-height fluid>
    <v-row justify="center">
      <v-col cols="11" xs="11" sm="11" md="6" lg="4" xl="3">
        <v-card>
          <v-row justify="center">
            <v-col cols="10">
              <v-img src="@/assets/rhc-logo-150.png" max-height="150px" contain class="my-5" />
              <v-form v-on:submit.prevent="doAction">
                <div class="welcomeTxt">Welcome to Royal Hair Care</div>
                <div class="welcomeTag mb-6">Appointment Scheduling Portal</div>
                <v-text-field v-if="step == 1" filled rounded required class="mobileNumber" v-model="mobileNumber"
                  type="tel" color="primary" placeholder="7XXXXXXXX" hint="Mobile Number" persistent-hint
                  append-icon="mdi-cellphone" maxlength="9" prefix="+94" :rules="rulesMobile" :disabled="working"
                  @keyup.enter="doAction" />
                <v-otp-input v-if="step == 2" length="6" class="otpTxt" type="number" v-model="otp" :disabled="working"
                  @keyup.enter="doAction"></v-otp-input>
                <div id="recaptcha-container"></div>
                <div class="errorTxt">{{ errorText }}</div>
                <v-btn x-large block rounded class="my-4" v-on:click="doAction" :loading="working" color="primary">{{
                  buttonText }}</v-btn>
              </v-form>
            </v-col>
          </v-row>
        </v-card>
        <div class="my-3">o</div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import firebase from "firebase/compat/app";
import { firebaseFirestore, firebaseFunctions } from "@/main";

export default {
  data()
  {
    return {
      valid: false,
      working: false,
      mobileNumber: "",
      appVerifier: "",
      otp: "",
      buttonText: "",
      errorText: "",
      step: 1,
      rulesMobile: [
        (value) => !!value || "Mobile number required",
        (value) => (value || "").length == 9 || "Enter mobile number in 7XXXXXXXX format",
      ],
      token: null,
      newUser: null,
    };
  },
  created()
  {
    this.initReCaptcha();
    this.buttonText = "Login";
  },
  methods: {
    doAction()
    {
      if (this.mobileNumber.length == 9)
      {
        if (this.step == 1)
        {
          this.startAuth();
        } else if (this.step == 2 && !this.newUser)
        {
          this.verifyOtp_phoneAuth();
        } else if (this.step == 2 && this.newUser)
        {
          this.verifyOtp_customAuth();
        } else
        {
          this.$router.go();
        }
      }else{
        console.log("No number")
      }
    },
    initReCaptcha()
    {
      setTimeout(() =>
      {
        let vm = this;
        window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
          "recaptcha-container",
          {
            size: "invisible",
            callback: function (response)
            {
              // reCAPTCHA solved, allow signInWithPhoneNumber.
              // ...
            },
            "expired-callback": function ()
            {
              // Response expired. Ask user to solve reCAPTCHA again.
              // ...
            },
          }
        );
        //
        this.appVerifier = window.recaptchaVerifier;
      }, 1000);
    },
    startAuth()
    {
      this.working = true;
      let vm = this;
      let countryCode = "+94";
      let phoneNumber = countryCode + this.mobileNumber;

      var getToken = firebaseFunctions.httpsCallable("getToken");

      getToken({ mobile: phoneNumber }).then((result) =>
      {
        this.newUser = result.data.newUser;
        if (result.data.response == "Success" && !result.data.newUser)
        {
          this.sendOTP_phoneAuth();
        } else if (result.data.response == "Success" && result.data.newUser)
        {
          vm.working = false;
          vm.buttonText = "Verify";
          vm.step = 2;
          vm.token = result.data.token;
        } else
        {
          vm.errorText = "Error sending OTP SMS. Please check the number and try again.";
          vm.buttonText = "Try again";
          vm.working = false;
          vm.step = 3;
        }
      });
    },
    sendOTP_phoneAuth()
    {
      this.working = true;
      let vm = this;
      let countryCode = "+94";
      let phoneNumber = countryCode + this.mobileNumber;

      // Turn off phone auth app verification.
      firebase.auth().settings.appVerificationDisabledForTesting = true;
      //
      let appVerifier = this.appVerifier;
      //
      firebase
        .auth()
        .signInWithPhoneNumber(phoneNumber, appVerifier)
        .then(function (confirmationResult)
        {
          // SMS sent. Prompt user to type the code from the message, then sign the
          // user in with confirmationResult.confirm(code).
          window.confirmationResult = confirmationResult;
          //
          vm.working = false;
          vm.buttonText = "Verify";
          vm.step = 2;
        })
        .catch(function (error)
        {
          // Error; SMS not sent
          // ...
          vm.errorText = "Error sending OTP SMS. Please check the number and try again.";
          vm.buttonText = "Try again";
          vm.working = false;
          vm.step = 3;

          //////////not possible
          firebaseFirestore.collection("logs").add({
            timestamp: firebase.firestore.FieldValue.serverTimestamp(),
            type: "error",
            action:
              "Error sending OTP SMS for number " +
              phoneNumber +
              " ------ Error: " +
              error,
          });
        });
    },
    verifyOtp_customAuth()
    {
      this.working = true;
      let vm = this;

      let countryCode = "+94";
      let phoneNumber = countryCode + this.mobileNumber;

      var login = firebaseFunctions.httpsCallable("login");

      login({ otp: this.otp, phoneNumber: phoneNumber }).then((result) =>
      {
        if (result.data.response == "Success")
        {
          firebase
            .auth()
            .signInWithCustomToken(vm.token)
            .then((userCredential) =>
            {
              // Signed in
              vm.loginToApp(userCredential);
            });
        } else
        {
          this.errorText = result.data.response;
          this.buttonText = "Try again";
          this.working = false;
          this.step = 3;
        }
      });
    },
    //
    verifyOtp_phoneAuth()
    {
      this.working = true;
      let vm = this;
      let code = this.otp;
      //
      window.confirmationResult
        .confirm(code)
        .then(function (userCredential)
        {
          // User signed in successfully.
          vm.loginToApp(userCredential);
        })
        .catch(function (error)
        {
          // User couldn't sign in (bad verification code?)
          // ...
          console.log(error);
          vm.errorText = "Wrong OTP Code";
          vm.buttonText = "Try again";
          vm.working = false;
          vm.step = 3;
        });
    },
    loginToApp(userCredential)
    {
      var user = userCredential.user;
      let vm = this;
      let countryCode = "+94";
      let phoneNumber = countryCode + this.mobileNumber;

      firebaseFirestore
        .collection("users")
        .doc(user.uid)
        .get()
        .then((returnedDoc) =>
        {
          if (returnedDoc.exists)
          {
            localStorage.mobile = returnedDoc.data().mobile;
            localStorage.uid = user.uid;
            localStorage.name = returnedDoc.data().name;
            localStorage.profile = returnedDoc.data().profile;

            if (returnedDoc.data().disabled)
            {
              vm.$router.push({ path: "/profile" });
            } else
            {
              vm.$router.push({ path: "/" });
            }
          } else
          {
            vm.createUser(phoneNumber);
          }
        });
    },
    createUser(phoneNumber)
    {
      var createUser = firebaseFunctions.httpsCallable("createUser");

      createUser({ mobile: phoneNumber }).then((result) =>
      {
        if (result.data.response == "Success")
        {
          localStorage.mobile = result.data.mobile;
          localStorage.uid = result.data.uid;
          localStorage.name = result.data.name;
          localStorage.profile = result.data.profile;
          this.$router.push({ path: "/profile" });
        } else
        {
          this.errorText = result.data.response;
          this.buttonText = "Try again";
          this.working = false;
          this.step = 3;
        }
      });
    },
  },
};
</script>

<style scoped>
.loginBack {
  width: 100vw;
  height: 100vh;
  background-color: rgb(46, 46, 46, 0.9);
  /* Tint color */
  background-blend-mode: multiply;
  background-image: url("~@/assets/rhcBackground.jpg");
  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.welcomeTxt {
  font-size: 1.3em;
  font-weight: 500;
  text-align: center;
}

.welcomeTag {
  font-size: 1em;
  font-weight: 400;
  text-align: center;
}

.mobileNumber {
  font-size: 1.4em;
}

.errorTxt {
  text-align: center;
  font-size: 1em;
  color: #ff1100;
  font-weight: 500;
}

.otpTxt {
  font-size: 1.4em;
}
</style>
