<template>
  <v-container fluid full-width>
    <v-row justify="center" v-if="slides.length != 0">
      <v-carousel
        cycle
        height="200"
        hide-delimiters
        hide-delimiter-background
        show-arrows-on-hover
      >
        <v-carousel-item v-for="(slide, i) in slides" :key="i">
          <v-sheet :color="colors[i]" height="100%">
            <v-row class="fill-height" align="center" justify="center">
              <v-col cols="12">
                <div class="white--text text-h6 ma-3 noticeText">{{ slide }}</div>
              </v-col>
            </v-row>
          </v-sheet>
        </v-carousel-item>
      </v-carousel>
    </v-row>
    <v-row justify="center" no-gutters class="align-self-start">
      <v-col xs="12" sm="12" md="6" lg="4" xl="3">
        <div v-if="state == 0" class="text-center mt-4">
          <v-sheet
            class="px-7 pt-7 pb-4 text-center d-inline-block"
            color="black"
            width="100%"
            dark
          >
            <div class="grey--text text--lighten-4 text-body-1 mb-4">
              You need to get your account verified from us to set an Appointment!
            </div>
            <div class="grey--text text--lighten-4 text-body-2 mb-4">
              Please go to edit profile and add your profile picture and name before
              requesting account verification.
            </div>

            <v-btn class="ma-1" color="grey" plain @click="sendVerification()">
              Request account verification
            </v-btn>
          </v-sheet>
        </div>
        <div v-if="state == 1" class="text-center mt-4">
          <v-sheet
            class="px-7 pt-7 pb-4 text-center d-inline-block"
            color="black"
            width="100%"
            dark
          >
            <div class="grey--text text--lighten-4 text-body-1 mb-2">
              <strong>Account verification sent.</strong>
              <br />
              Please wait till Royal Hair Care Staff approve your account. Once your
              account is approved you will be able to set appointments.
            </div>
          </v-sheet>
        </div>
        <div v-if="state == 2" class="text-center mt-4">
          <v-sheet
            class="px-7 pt-7 pb-4 text-center d-inline-block"
            color="black"
            width="100%"
            dark
          >
            <div class="grey--text text--lighten-4 text-body-1 mb-4">
              You have no appointments yet!
            </div>

            <v-btn class="ma-1" color="grey" plain @click="gotoAppointment()">
              Set an appointment
            </v-btn>
          </v-sheet>
        </div>
        <v-card v-if="state == 3" color="cardBackground" dark class="mt-2">
          <v-card-title> Your Next Appointment </v-card-title>
          <v-card-text class="text-body-1">
            Your next appointment is on {{ nextText }}</v-card-text
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="red accent-4" @click="confirmDialog = true">
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-card :height="getHeight" color="background" elevation="0" />
    <v-row justify="center" class="align-self-end">
      <v-col xs="12" sm="12" md="6" lg="4" xl="2">
        <v-btn color="grey" plain block href="/history"> Appointment History </v-btn>
      </v-col>
    </v-row>
    <v-dialog v-model="confirmDialog" max-width="290" persistent>
      <v-card>
        <v-card-title class="text-h6">Are you sure?</v-card-title>
        <v-card-text class="text-body-1">
          Do you really want to cancel this appointment.
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green darken-1"
            text
            @click="confirmDialog = false"
            :disabled="working"
          >
            Cancel
          </v-btn>

          <v-btn
            color="green darken-1"
            text
            @click="cancelAppointment()"
            :loading="working"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="successDialog" max-width="290" persistent>
      <v-card>
        <v-card-title class="text-h6 green--text">Success</v-card-title>

        <v-card-text class="text-body-1"> Your appointment is cancelled! </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="reload()"> Okay </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="errorDialog" max-width="290" persistent>
      <v-card>
        <v-card-title class="text-h6 red--text">{{ errorTitle }}</v-card-title>

        <v-card-text class="text-body-1">
          {{ errorText }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="reload()">Okay</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="reloadDialog" max-width="290" dark>
      <v-card>
        <div class="text-h6 py-4 noticeText">
          New content available
        </div>
        <v-card-actions>
          <v-btn block @click="reload">
            Reload
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="hasSaved" :timeout="2000" absolute bottom left dark>
      {{ snackText }}
    </v-snackbar>
  </v-container>
</template>

<script>
import {
  firebaseAuth,
  firebaseFirestore,
  firebaseFunctions,
  serverTime,
  firebaseStorage,
} from "@/main";
import { EventBus } from "@/main";
import moment from "moment";

export default {
  name: "home",
  data() {
    return {
      loading: true,
      working: false,
      saving: false,
      isEditing: false,
      hasSaved: false,
      snackText: "",
      name: "Press icon to set your name",
      disabled: false,
      colors: [],
      slides: [],
      state: null,
      verifiedText: "Request account verification",
      nextText: "",
      docID: "",
      confirmDialog: false,
      successDialog: false,
      errorDialog: false,
      errorTitle: "",
      errorText: "",
      //RELOAD
      reloadDialog: false,
    };
  },
  components: {},
  created() {
    this.loading = true;
    this.emitLoadingState();
    this.getBanner();
    if (localStorage.name != "") {
      this.name = localStorage.name;
    }
    this.setLoginTime();
    setTimeout(()=> {this.reloadDialog = true}, 900000);
  },
  computed: {
    getHeight() {
      return (window.innerHeight / 100) * 25;
    },
  },
  methods: {
    emitLoadingState() {
      EventBus.$emit("loadingState", this.loading);
    },
    setLoginTime() {

      firebaseFirestore
        .collection("users")
        .doc(firebaseAuth.currentUser.uid)
        .collection("last")
        .doc("login")
        .set(
          {
            timestamp: serverTime,
          },
          { merge: true }
        )
        .then(() => {
          this.getVerified();
          this.logoutCheck();
        });
    },
    getVerified() {
      firebaseFirestore
        .collection("users")
        .doc(firebaseAuth.currentUser.uid)
        .get()
        .then((docSnap) => {
          this.disabled = docSnap.data().disabled;
          localStorage.profile = docSnap.data().profile;
          localStorage.name = docSnap.data().name;
        })
        .then(() => {
          if (this.disabled == false) {
            this.getAppointment();
          } else {
            this.verifySent(localStorage.mobile);
          }
        });
    },
    getAppointment() {
      firebaseFirestore
        .collection("users")
        .doc(firebaseAuth.currentUser.uid)
        .collection("appointments")
        .where("cancelled", "==", false)
        .orderBy("timestamp", "desc")
        .limit(1)
        .get()
        .then((querySnapshot) => {
          if (querySnapshot.empty) {
            this.state = 2;
            this.loading = false;
            this.emitLoadingState();
            localStorage.state = this.state;
          } else {
            querySnapshot.forEach((doc) => {
              if (doc.data().cancelled == true) {
                this.state = 2;
              } else {
                var timeNow = new Date();
                if (doc.data().end.toDate() > timeNow) {
                  this.nextText =
                    this.formatDate(doc.data().start.toDate()) +
                    " at " +
                    doc.data().slotString;
                  this.docID = doc.id;
                  this.state = 3;
                } else {
                  this.state = 2;
                }
              }
              localStorage.state = this.state;
            });
            this.loading = false;
            this.emitLoadingState();
          }
        });
    },
    logoutCheck() {
      firebaseFirestore
        .collection("users")
        .doc(firebaseAuth.currentUser.uid)
        .collection("commands")
        .doc("logout")
        .get()
        .then((doc) => {
          if (doc.data().logout == true) {
            this.setLogout();
          }
        }).catch((error)=>{
          //no doc
        });
    },
    setLogout() {
      firebaseFirestore
        .collection("users")
        .doc(firebaseAuth.currentUser.uid)
        .collection("commands")
        .doc("logout")
        .set({
          logout: false,
        })
        .then(() => {
          this.logout();
        });
    },
    verifySent() {
      firebaseFirestore
        .collection("verification")
        .doc(localStorage.mobile)
        .get()
        .then((returnedDoc) => {
          if (returnedDoc.exists) {
            this.state = 1;
            setTimeout(this.reload, 60000);
          } else {
            this.state = 0;
          }
          this.loading = false;
          this.emitLoadingState();

          localStorage.state = this.state;
        })
        .catch((error) => {
          this.state = 0;
          this.loading = false;
          this.emitLoadingState();

          localStorage.state = this.state;
        });
    },
    cancelAppointment() {
      this.working = true;

      var cancelAppointment = firebaseFunctions.httpsCallable("cancelAppointment");
      cancelAppointment({
        docID: this.docID,
      }).then((result) => {
        if (result.data.response == "Success") {
          this.confirmDialog = false;
          this.working = false;
          this.successDialog = true;
        } else if (result.data.response == "Locked") {
          this.errorTitle = "Locked";
          this.errorText = result.data.error;
          this.confirmDialog = false;
          this.working = false;
          this.errorDialog = true;
        } else if (result.data.response == "Error") {
          this.errorTitle = "Error";
          this.errorText = result.data.error;
          this.confirmDialog = false;
          this.working = false;
          this.errorDialog = true;
        }
      });
    },
    formatDate(timeStamp) {
      var m = moment(timeStamp);
      var momentString = m.format("dddd Do of MMMM YYYY");
      return momentString;
    },
    gotoAppointment() {
      window.location.href =
        "/appointment/" +
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10);
    },
    save() {
      this.saving = true;
      if (this.name.trim() != "Press icon to set your name") {
        var setName = firebaseFunctions.httpsCallable("setName");
        setName({
          name: this.name,
        }).then((result) => {
          this.snackText = result.data.message;
          this.hasSaved = true;
          this.isEditing = false;
          this.saving = false;
        });
      } else {
        this.snackText = "Not changed";
        this.hasSaved = true;
        this.isEditing = false;
        this.saving = false;
      }
    },
    sendVerification() {
      this.loading = true;
      this.emitLoadingState();
      firebaseFirestore
        .collection("verification")
        .doc(localStorage.mobile)
        .set(
          {
            mobile: localStorage.mobile,
            uid: firebaseAuth.currentUser.uid,
            timestamp: serverTime,
          },
          { merge: false }
        )
        .then(() => {
          this.state = 1;
          setTimeout(this.reload, 60000);
          this.loading = false;
          this.emitLoadingState();

          localStorage.state = this.state;
        });
    },
    getBanner() {
      firebaseFirestore
        .collection("banner")
        .orderBy("order")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.slides.push(doc.data().text);
            this.colors.push(doc.data().color);
          });
        });
    },
    logout: function () {
      firebaseAuth.signOut().then(() => {
        localStorage.clear();
        this.$router.go({ path: this.$router.path });
      });
    },
    reload() {
      this.$router.go();
    },
  },
};
</script>

<style scoped>
.headingText {
  color: white;
  font-size: 2em;
  font-weight: 700;
}
.mobileText {
  color: #f5f5f5;
  font-size: 1.4em;
  font-weight: 500;
}
.noticeText {
  text-align: center;
}
</style>
