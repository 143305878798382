<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12"
        ><v-data-table
          dark
          :headers="headers"
          :items="appointments"
          :page.sync="page"
          :loading="tableLoading"
          item-key="timestamp"
          class="elevation-1"
          hide-default-footer
          @page-count="pageCount = $event"
        >
          <template v-slot:[`item.cancelled`]="{ item }">
            <div :class="cancelledColor(item.cancelled)">{{ item.cancelled }}</div>
          </template>

          <template v-slot:footer>
            <v-row justify="center" align="center">
              <v-col cols="12" xs="12" sm="12" md="9" lg="9" xl="10">
                <v-pagination
                  v-model="page"
                  :length="pageCount"
                  total-visible="6"
                ></v-pagination>
              </v-col> </v-row></template></v-data-table></v-col
    ></v-row>
  </v-container>
</template>

<script>
import { firebaseAuth, firebaseFunctions, firebaseFirestore, serverTime } from "@/main";
import { EventBus } from "@/main";
import moment from "moment";

export default {
  data: () => ({
    loading: true,
    tableLoading: true,
    totalItems: 10,
    page: 1,
    pageCount: 0,
    headers: [
      {
        text: "Booked At",
        align: "start",
        value: "created",
      },
      { text: "Appointment Date", value: "dateString" },
      { text: "Time Slot", value: "slotString" },
      { text: "Status", value: "cancelled" },
      { text: "By", value: "cancelledBy" },
      { text: "At", value: "cancelledAt" },
    ],
    appointments: [],
    lastDoc: null,
    docRef: null,
  }),
  created() {
    this.loading = true;
    this.emitLoadingState();
    this.getHistory(firebaseAuth.currentUser.uid);
  },
  methods: {
    emitLoadingState() {
      EventBus.$emit("loadingState", this.loading);
    },
    getHistory(userID) {
      this.tableLoading = true;

      if (this.lastDoc == null) {
        this.docRef = firebaseFirestore
          .collection("users")
          .doc(userID)
          .collection("appointments")
          .orderBy("timestamp", "desc")
          .limit(30);
      } else {
        this.docRef = firebaseFirestore
          .collection("users")
          .doc(userID)
          .collection("appointments")
          .orderBy("timestamp", "desc")
          .startAfter(this.lastDoc)
          .limit(30);
      }

      this.docRef.get().then((querySnapshot) => {
        this.lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];
        querySnapshot.forEach((doc) => {
          const data = {
            cancelled: this.cancelledText(doc.data().cancelled),
            cancelledBy: doc.data().cancelledBy,
            cancelledAt: this.cancelledTimeProcess(doc.data().cancelledAt),
            dateString: doc.data().dateString,
            slotString: doc.data().slotString,
            created: this.formatDate(doc.data().timestamp.toDate()),
            timestamp: doc.data().timestamp.toDate().toUTCString(),
          };
          this.appointments.push(data);
        });
        this.tableLoading = false;
        this.loading = false;
        this.emitLoadingState();
      });
    },
    cancelledText(cancelled) {
      if (cancelled) {
        return "Cancelled";
      } else {
        return " Active ";
      }
    },
    cancelledTimeProcess(dataIn) {
      if (dataIn == "") {
        return "";
      } else {
        return this.formatDate(dataIn.toDate());
      }
    },
    cancelledColor(cancelled) {
      if (cancelled == "Cancelled") {
        return "error--text";
      } else {
        return "grey--text";
      }
    },
    formatDate(timeStamp) {
      var m = moment(timeStamp);
      var mFormatted = m.format("DD-MM-YYYY HH:MM:SS");
      return mFormatted;
    },
  },
};
</script>

<style></style>
