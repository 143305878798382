<template>
  <div>
    <v-footer dark padless>
      <v-card class="flex" flat tile>
        <div class="white--text text-center text-caption my-2">
          <v-btn v-for="icon in icons" :key="icon" class="mx-4" dark icon>
            <v-icon size="24px" @click="socialAction(icon)">
              {{ icon }}
            </v-icon>
          </v-btn>
        </div>
        <div class="text-center mb-4">
          {{ new Date().getFullYear() }} — <strong>Royal Hair Care (Pvt) Ltd</strong>
        </div>
      </v-card>
    </v-footer>
  </div>
</template>

<script>
export default {
  data: () => ({
    icons: ["mdi-facebook", "mdi-phone", "mdi-chat-processing"],
  }),
  methods:{
    socialAction(name){
      if(name == this.icons[0]){
        window.open('https://www.facebook.com/Royalhaircare.lk/', '_blank');
      }else if(name == this.icons[1]){
        window.open('tel:+94777921399', '_blank');
      }else if(name == this.icons[2]){
        window.open('https://wa.me/94777921399', '_blank');
      }
    }
  }
};
</script>

<style></style>
