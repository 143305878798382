<template>
  <div>
    <v-app-bar
      app
      dark
      color="primary"
      shrink-on-scroll
      prominent
      src="@/assets/rhcBackground.jpg"
      fade-img-on-scroll
    >
      <template v-slot:img="{ props }">
        <v-img
          v-bind="props"
          gradient="to top right, rgba(0,0,0,.7), rgba(66,66,66,.7)"
        ></v-img>
      </template>
      <v-app-bar-title>{{ title }}</v-app-bar-title>

      <v-spacer></v-spacer>

      <v-btn fab href="/" class="mr-3" color="black">
        <v-icon>mdi-home</v-icon>
      </v-btn>
      <v-menu v-model="menu" :close-on-content-click="false" :nudge-width="200" offset-x
        ><template v-slot:activator="{ on, attrs }">
          <v-btn fab color="black" v-bind="attrs" v-on="on" @click="setProfile">
            <v-icon color="white"> mdi-menu </v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-list>
            <v-list-item>
              <v-list-item-content
                :style="{
                  'align-item': 'center',
                }"
              >
                <v-avatar size="120">
                  <v-img
                    max-width="120"
                    :src="this.profile"
                    @error="imageLoadError"
                    lazy-src="https://firebasestorage.googleapis.com/v0/b/royal-hair-care.appspot.com/o/profile.jpg?alt=media&token=c4156c55-7064-421d-bf1d-910d94870210"
                  >
                    <template v-slot:placeholder>
                      <v-row align="center" justify="center" class="mt-10">
                        <v-progress-circular
                          indeterminate
                          color="black"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-avatar>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="text-h6">{{ this.mobile }}</v-list-item-title>
                <v-list-item-subtitle class="text-button">{{
                  this.name
                }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-chip v-if="state==2 || state==3" color="green" outlined pill small>
                Verified
                <v-icon right small> mdi-account-outline </v-icon>
              </v-chip>
            </v-list-item>
          </v-list>

          <v-divider></v-divider>

          <v-list>
            <v-list-item>
              <v-btn text block large href="/profile"> Edit profile </v-btn>
            </v-list-item>
          </v-list>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="logout"> Logout </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
      <v-progress-linear
        :active="loading"
        indeterminate
        absolute
        bottom
        height="3"
        color="white"
      ></v-progress-linear>
    </v-app-bar>
  </div>
</template>

<script>
import { EventBus } from "@/main";
import { firebaseAuth, firebaseFirestore } from "@/main";

export default {
  data() {
    return {
      title: "",
      loading: true,
      working: false,
      menu: false,
      offset: true,
      mobile: localStorage.mobile,
      name: localStorage.name,
      state: localStorage.state,
      profile:
        "https://firebasestorage.googleapis.com/v0/b/royal-hair-care.appspot.com/o/profile.jpg?alt=media&token=c4156c55-7064-421d-bf1d-910d94870210",
    };
  },
  watch: {
    $route() {
      this.updateTitle();
    },
  },
  mounted() {
    this.updateTitle();
  },
  created() {
    const self = this;
    EventBus.$on("loadingState", (payload) => {
      self.loading = payload;
    });
    firebaseAuth.currentUser.providerData.forEach((profile) => {
      console.log("Sign-in provider: " + profile.providerId);
      console.log("  Provider-specific UID: " + profile.uid);
      console.log("  Name: " + profile.displayName);
      console.log("  Email: " + profile.email);
      console.log(" Mobile number " + profile.phoneNumber);
      localStorage.mobile = profile.phoneNumber;
    });
    self.profile = localStorage.profile;
    self.state = localStorage.state;
  },
  methods: {
    updateTitle() {
      const self = this;
      self.title = self.$route.meta.title;
    },
    setProfile() {
      this.profile = localStorage.profile;
      this.state = localStorage.state;
    },
    imageLoadError(){
      firebaseFirestore
        .collection("users")
        .doc(localStorage.uid)
        .get()
        .then((docSnap) => {
          localStorage.profile = docSnap.data().profile;
          this.profile = docSnap.data().profile;
        });
    },
    logout: function () {
      firebaseAuth.signOut().then(() => {
        localStorage.clear();
        this.$router.go({ path: this.$router.path });
      });
    },
  },
};
</script>

<style>
.v-app-bar-title__content {
  width: 200px !important;
}
</style>
