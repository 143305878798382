<template>
  <div id="app">
    <v-app id="inspire" :style="{ background: $vuetify.theme.themes[theme].background }">
    <Navbar v-if="!$route.meta.requiresGuest" />
    <v-main>
      <router-view />
    </v-main>
    <Footer/>
  </v-app>
  </div>
</template>

<script>
import Navbar from "@/components/navbar";
import Footer from "@/components/footer";

export default {
  name: "App",
  data: () => ({
    //
  }),
  components: {
    Navbar,
    Footer,
  },
  computed: {
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
  },
};
</script>
