import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import Croppa from 'vue-croppa'

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/functions';
import 'firebase/compat/messaging';
import 'firebase/compat/storage';

const firebaseConfig = {
  apiKey: "AIzaSyAZuN2ZhljHW-R0BoGhO-Gz7iKkUTlYfNY",
  authDomain: "royal-hair-care.firebaseapp.com",
  projectId: "royal-hair-care",
  storageBucket: "royal-hair-care.appspot.com",
  messagingSenderId: "503144185257",
  appId: "1:503144185257:web:12d18ef2428350281158b8",
  measurementId: "G-LLW3XLM6F3"
};

// Initialize Firebase
const firebaseApp = firebase.initializeApp(firebaseConfig);

export const firebaseFirestore = firebaseApp.firestore();
export const firebaseAuth = firebase.auth();
export const firebaseFunctions = firebase.app().functions('asia-south1');
export const firebaseStorage = firebase.storage();
export const serverTime = firebase.firestore.FieldValue.serverTimestamp();

export const EventBus = new Vue();

Vue.config.productionTip = false

let app;
firebase.auth().onAuthStateChanged(user => {
  if (!app) {
    console.log("USER - " + user);
    if(user != null){
      firebaseFirestore.collection("users").doc(user.uid).collection("last").doc("login").set({
        timestamp: serverTime,
      },{ merge: true});
    }
    app = new Vue({
      router,
      store,
      vuetify,
      Croppa,
      render: h => h(App)
    }).$mount('#app')
  }
});
